import axiosHelper from "../../../../core/axios-helper";

import { toGraphFriendlyDate } from "../../../../utils/dateFormat";
import { reportServiceBaseUrl } from "../../../../core/config";
import { FAILURE_REASONS_COLORS } from "../constants";

const ENDPOINTS = {
    TRANSACTION_OVERVIEW: "/transaction/overview",
};

const LEGENDS = {
    APPROVED: "Approved",
    DECLINED: "Declined",
    SUCCESS_RATE: "Success rate",
}

export const getTxnOverview = async (merchantId, fromDate, toDate) => {
    let url = `${reportServiceBaseUrl}${ENDPOINTS.TRANSACTION_OVERVIEW}?merchantId=${merchantId}`
    if (fromDate && toDate) {
        url += `&fromDate=${fromDate}&toDate=${toDate}`
    }
    const response = await axiosHelper.withMerchantAuth.get(url);
    return response
}

export const processSuccessRate = (source) => {
    return source.map(item => ({
        date: toGraphFriendlyDate(item.date),
        [LEGENDS.SUCCESS_RATE]: parseFloat(((item.approved / item.total) * 100).toFixed(2)),
    }));
}

export const processTxnVolume = (source) => {
    return source.map(item => ({
        date: toGraphFriendlyDate(item.date),
        [LEGENDS.APPROVED]: item.approved,
        [LEGENDS.DECLINED]: item.declined,
    }));
}

export const processFailureReasons = (source) => {
    return source.map(item => {
        // Extracting all reason codes from each item
        const reasonCodes = item.reasonCodes.reduce((collection, reason) => {
            if (reason.code != "00") { // ignore approved code "00"
                collection[reason.code] = parseFloat(((reason.count / item.declined) * 100).toFixed(2))
            }
            return collection;
        }, {});

        // Merging extracted reason codes with other properties
        const processedItem = {
            date: toGraphFriendlyDate(item.date),
            ...reasonCodes
        };

        return processedItem;
    });
}

export const generateFailureReasonsKeys = (source) => {
    const keys = { X: "date", Y: [] };
    source.forEach((item) => {
        item.reasonCodes.forEach((reason, index) => {
            if (reason.code != "00" && !keys.Y.find(item => item.dataKey === reason.code)) {
                keys.Y.push({
                    dataKey: reason.code,
                    fill: FAILURE_REASONS_COLORS[index % FAILURE_REASONS_COLORS.length],
                    name: `${reason.code} ${reason.label} `,
                    stackId: "a"
                });
            }
        });
    });
    return keys;
}
