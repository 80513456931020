import React from "react";
import { Grid } from "basis";

import { Column, Responsive } from "../../../../components";
import { CardSmall, CardBig, CardHeading } from "../../components";

import { resourceBaseUrl } from "../../../../core/config";
import { MERCHANT_PAGE_ROUTES } from "../../../../core/constants";
import MENU_CARDS from "./MenuCards";

const Tiles = ({ profile }) => {
    return (
        <>
            <Grid.Item colSpan="all">
                {/* inner grid */}
                <Grid preset="page" rowsGap={4} colsGap={4}>
                    <Grid.Item colSpan="all">
                        <CardHeading title="Get started">
                            Select a quick action below, or take a look around using the
                            main navigation.
                        </CardHeading>
                    </Grid.Item>
                </Grid>
                {/* inner grid end */}

                <Responsive margin='15px 0 0 0' justifyRow='flex-start'>
                    {
                        MENU_CARDS.map((card, idx) => {
                            const { requiredClaim, title, icon, link, content } = card;
                            const claims = profile?.userClaims ?? [];

                            return claims.includes(requiredClaim) &&
                                <Column
                                    key={idx}
                                    templateDesktop='430px'
                                    templateTablet='400px'
                                    templateMobile='310px'
                                    template='400px'
                                >
                                    <CardSmall
                                        icon={icon}
                                        title={title}
                                        content={content}
                                        link={link}
                                        newTab={false}
                                    />
                                </Column>
                        })
                    }
                </Responsive>

            </Grid.Item>

            <Grid.Item colSpan="all">
                {/* inner grid */}
                <Grid preset="page" rowsGap={4} colsGap={4}>
                    <Grid.Item colSpan-lg="0-5" colSpan-sm="all" colSpan="all">
                        <CardBig
                            icon="shoppingBags"
                            title="How do I make an Interest Free order?"
                            content="View example flow that customers will experience in your store."
                            link={MERCHANT_PAGE_ROUTES.TrainingGuides}
                            newTab={false}
                            buttonName="View Guides"
                        />
                    </Grid.Item>

                    <Grid.Item colSpan-lg="6-11" colSpan-sm="all" colSpan="all">
                        <CardBig
                            icon="faqs"
                            title="Frequently asked questions"
                            content="How to search, refund and more in the FAQs."
                            link={`${resourceBaseUrl}/interest-free/merchant-resources/partner-hub-resources/faq-partner-hub/`}
                            newTab={true}
                            buttonName="View FAQs"
                        />
                    </Grid.Item>
                </Grid>
                {/* inner grid end */}
            </Grid.Item>
        </>
    )
};

export default Tiles;
