export const FAILURE_REASONS_COLORS = [
    "#4F638E", // Latitude Blue (One Shade Darker)
    "#5F79C5", // Light Blue (One Shade Highlighted)
    "#9AA8D9", // Soft Blue
    "#8B9FDA", // Gentle Blue
    "#7597D8", // Mild Blue
    "#688FD7", // Calm Blue
    "#5176CC", // Muted Blue
    "#748EE1", // Pastel Blue
    "#7E99E4", // Powder Blue
    "#8EA5E7", // Pale Blue
    "#B2BDFF", // Lightened Blue
    "#B1BBFF", // Washed Blue
    "#ACB9FF", // Faded Blue
    "#B9C4FF", // Subdued Blue
    "#B2CFFF", // Delicate Blue
    "#A3BBFF", // Whisper Blue
    "#AAC0FF", // Hushed Blue
    "#9CB1FF", // Serene Blue
    "#97ACFF", // Tranquil Blue
    "#8FA6FF", // Dreamy Blue
    "#AAC0F7", // Softened Blue
    "#9AAEEC", // Ethereal Blue
    "#90A7F3", // Airy Blue
    "#A0B6FF", // Subtle Sky Blue
    "#89A0FF", // Pale Sky Blue
    "#E5AB89", // Subtle Orange
    "#E5BB99", // Soft Orange
    "#E5CBA9", // Gentle Orange
    "#E5DBB9", // Mild Orange
    "#FFF6D1", // Calm Orange
    "#FDC69F", // Muted Orange
    "#F7A3A3", // Subtle Red
    "#F59595", // Soft Red
    "#F38787", // Gentle Red
    "#F17979", // Mild Red
    "#EE6B6B", // Calm Red
    "#EB5D5D"  // Muted Red
];

export const SUCCESS_RATE_KEYS = {
    X: "date",
    Y: [
        { dataKey: "Success rate", fill: "green" },
    ]
}

export const TXN_VOLUME_KEYS = {
    X: "date",
    Y: [
        { dataKey: "Approved", fill: "blue" },
        { dataKey: "Declined", fill: "red" },
    ]
}
