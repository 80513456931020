import React, { useEffect, useState } from "react";
import { Grid } from "basis";

import { BarGraphStacked, DateRangePicker, ErrorMessage, LineGraph, PolarisProvider } from "../../../../components";
import { CardBig } from "../../components";

import { resourceBaseUrl } from "../../../../core/config";
import { SUCCESS_RATE_KEYS, TXN_VOLUME_KEYS } from "../constants";
import { getTxnOverview, processSuccessRate, processTxnVolume, processFailureReasons, generateFailureReasonsKeys } from "../utils/fetchData";
import { toFriendlyDate, toDateString } from "../../../../utils/dateFormat";

const Graphs = ({ profile }) => {
    const [error, setError] = useState(false);
    const [selectedDates, setSelectedDates] = useState({ start: null, end: null });
    const [successRate, setSuccessRate] = useState(null);
    const [txnVolume, setTxnVolume] = useState(null);
    const [failureReasons, setFailureReasons] = useState(null);
    const [failureReasonsKeys, setFailureReasonsKeys] = useState({ X: "", Y: [] });
    useEffect(() => {
        initGraphs();
    }, [profile]);

    const initGraphs = async (fromDate, toDate) => {
        const response = await getTxnOverview(profile.merchantId, fromDate, toDate)
        if (response.data) {
            setError(false)
            updateGraphs(response.data)
        } else {
            setError(response.message)
        }
    }

    const updateGraphs = (data) => {
        setSuccessRate(processSuccessRate(data))
        setTxnVolume(processTxnVolume(data))
        setFailureReasons(processFailureReasons(data))
        setFailureReasonsKeys(generateFailureReasonsKeys(data))
    }

    const selectedDateRangeDisplay =
        selectedDates.start != null && selectedDates.end != null
            ? `${toFriendlyDate(selectedDates.start)} - ${toFriendlyDate(selectedDates.end)}`
            : ""

    const handleDateRangeChange = (value) => {
        setSelectedDates(value)
        if (value.start != null && value.end != null) {
            initGraphs(toDateString(value.start), toDateString(value.end))
        }
    }

    if (error) {
        return (
            <Grid.Item colSpan="all">
                <ErrorMessage description="Unable to display graphs" />
            </Grid.Item>
        );
    }

    return (
        <>
            <Grid.Item colSpan="all">
                {/* inner grid */}
                <Grid preset="page" rowsGap={4} colsGap={4}>

                    <Grid.Item colSpan-lg="0-5" colSpan-sm="all" colSpan="all">
                        <LineGraph title="Success rate (%)" chartData={successRate} yLabel="" xDataKey={SUCCESS_RATE_KEYS.X} lineDataKeys={SUCCESS_RATE_KEYS.Y} showLegend={false} />
                    </Grid.Item>

                    <Grid.Item colSpan-lg="6-11" colSpan-sm="all" colSpan="all">
                        <BarGraphStacked title="Failure reasons (%)" chartData={failureReasons} yLabel="" xDataKey={failureReasonsKeys.X} lineDataKeys={failureReasonsKeys.Y} showLegend={false} />
                    </Grid.Item>

                    <Grid.Item colSpan-lg="0-5" colSpan-sm="all" colSpan="all">
                        <LineGraph title="Transaction volume" chartData={txnVolume} yLabel="" xDataKey={TXN_VOLUME_KEYS.X} lineDataKeys={TXN_VOLUME_KEYS.Y} showLegend={false} />
                    </Grid.Item>

                    <Grid.Item colSpan-lg="6-11" colSpan-sm="all" colSpan="all">
                        <CardBig
                            icon="faqs"
                            title="Frequently asked questions"
                            content="How to search, refund and more in the FAQs."
                            link={`${resourceBaseUrl}/interest-free/merchant-resources/partner-hub-resources/faq-partner-hub/`}
                            newTab={true}
                            buttonName="View FAQs"
                        />
                    </Grid.Item>
                </Grid>
                {/* inner grid end */}
            </Grid.Item>

            <Grid.Item colSpan-lg="0-2" colSpan-sm="all" colSpan="all">
                <PolarisProvider>
                    <DateRangePicker value={selectedDateRangeDisplay} onChange={handleDateRangeChange} />
                </PolarisProvider>
            </Grid.Item>
        </>
    )
};

export default Graphs;
