import React from "react";
import { Grid, Text } from "basis";

import { useMerchantAuth } from "../../../core/auth";
import { LoadingMessage } from "../../../components";
import { CardSmall, NavigationalLayout } from "../components";

import Graphs from "./components/Graphs";
import Tiles from "./components/Tiles";

const Scene = () => {
  const { loading, profile } = useMerchantAuth();

  if (loading) {
    return (
      <NavigationalLayout>
        <LoadingMessage />
      </NavigationalLayout>
    );
  }

  return (
    <NavigationalLayout>
      <Grid preset="page" rowsGap={4}>
        <Grid.Item colSpan="all">
          <Text as="h3" textStyle="heading3">
            Hello {profile?.userGivenName}
          </Text>
        </Grid.Item>

        <Grid.Item colSpan="all">
          <CardSmall
            icon="party"
            title="Welcome to the Partner Hub"
            content="We hope you love the new one stop merchant shop! Check back regularly to see the latest features."
          />
        </Grid.Item>

        {profile?.features?.isSLAEnabled
          ? <Graphs profile={profile} />
          : <Tiles profile={profile} />
        }
      </Grid>
    </NavigationalLayout>
  );
};

export default Scene;
