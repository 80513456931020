import { USER_CLAIM } from "../../../../core/auth/constants"
import { MERCHANT_PAGE_ROUTES } from "../../../../core/constants"

const MENU_CARDS = [
    {
        title: 'Create Order',
        content: 'Send a new order to customer',
        icon: 'shoppingBag',
        link: MERCHANT_PAGE_ROUTES.Create,
        requiredClaim: USER_CLAIM.ORDERS_CREATE
    },
    {
        title: "View Orders",
        content: "View and search orders",
        icon: 'shoppingBag',
        link: MERCHANT_PAGE_ROUTES.OrderDashboard,
        requiredClaim: USER_CLAIM.ORDERS_READ
    },
    {
        title: 'View Analytics',
        content: 'Access Analytics Page',
        icon: 'list',
        link: MERCHANT_PAGE_ROUTES.Analytics,
        requiredClaim: USER_CLAIM.ANALYTICS_READ
    },
    {
        title: 'Reports',
        content: 'View and download reports',
        icon: 'lightbulb',
        link: MERCHANT_PAGE_ROUTES.Reports,
        requiredClaim: USER_CLAIM.STATEMENTS_READ
    },
];

export default MENU_CARDS;